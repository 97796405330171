import React from 'react'
import clsx from 'clsx'
import Button from '../Button/Button'
import CMSText from '../Global/CMSText'
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined'
import { ReactSVG } from 'react-svg'
import { useBasketStyles } from './Basket.styles'
import { useTranslation } from 'react-i18next'

export const BasketButton = ({
  isFunnel,
  productsCount,
  layoutStyle,
  toggleBasketDetails,
  basketIcon,
  showBasketWording,
  settings,
}) => {
  const classes = useBasketStyles(layoutStyle)
  const { t } = useTranslation()

  return (
    <Button
      id="menu-toggler"
      data-testid={`basketButton`}
      className={clsx(
        classes.basketBtn,
        isFunnel && classes.basketBtnReadOnly,
        productsCount === 0 && classes.basketBtnReadOnly,
        'basket__btn'
      )}
      disableRipple
      aria-controls="cart-menu"
      aria-haspopup="true"
      onClick={
        !isFunnel && productsCount !== 0 ? toggleBasketDetails : undefined
      }
    >
      {basketIcon?.url && basketIcon?.url?.length > 0 ? (
        basketIcon?.url.split('.').pop() === 'svg' ? (
          <ReactSVG
            src={basketIcon?.url}
            className={clsx(
              'basket__icon',
              'basket__icon--svg',
              classes.basketBtnIcon,
              classes.basketBtnIconSvg
            )}
            wrapper="span"
          />
        ) : (
          <img
            src={basketIcon?.url}
            className={clsx('basket__icon', classes.basketBtnIcon)}
            alt="basket__icon"
          />
        )
      ) : (
        <LocalMallOutlinedIcon />
      )}
      {showBasketWording ? (
        <CMSText
          data={settings?.my_basket}
          defaultText={t('texts:general:my_basket')}
        />
      ) : null}
      {productsCount > 0 && (
        <div className={clsx('cart-badge', classes.badge)}>{productsCount}</div>
      )}
    </Button>
  )
}
