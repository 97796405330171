import React from 'react'

type TProps = {
  condition: boolean
  wrap: Function
  children: React.ReactNode
}

const ConditionalWrap = ({ condition, wrap, children }: TProps) =>
  condition ? wrap(children) : children

export default ConditionalWrap
