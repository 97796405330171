import {
  createStyles,
  InputBase,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import CMSText, { prismicText } from '../Global/CMSText'
import { IconButton } from '@mui/material'
import { Cancel, Search } from '@material-ui/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 568,
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%',
        padding: '0 20px',
      },
    },
    searchInput: {
      display: 'flex',
      height: 38,
      padding: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'space-between',
      boxShadow: 'none',
      '& div': {
        flex: 1,
      },
      border: `1px solid ${theme.extraColors.grey}`,
      backgroundColor: `${theme.extraColors.greyLight}`,
      '&:hover': {
        backgroundColor: `${theme.extraColors.greyLight}`,
      },
      textDecoration: 'none',
      '& :hover': {
        backgroundColor: 'transparent',
      },
    },
    searchIcon: {
      height: 30,
      width: 30,
      borderRadius: 6,
    },
    clearIcon: {
      height: 15,
      width: 15,
      '&.clear_input_icon_button': {
        marginRight: theme.spacing(0.5),
        color: theme.palette.common.black,
        opacity: 0.54,
      },
    },
    btnIcon: {
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
    },
    searchIconSvg: {
      color: theme.palette.common.black,
    },
    btnIconSvg: {
      color: theme.palette.common.black,
      lineHeight: 1,
      '& .injected-svg': {
        display: 'inline-block',
        width: '1em',
        height: '1em',
        fontSize: '1.5rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
      },
    },
  })
)

interface SearchInputProps {
  search: string
  setSearch: (search: string) => void
  setOpen?: (open: boolean) => void
  label?: any[]
  placeholder?: any[]
  searchIconUrl?: string
  action: () => void
}

const SearchInput = ({
  search,
  setSearch,
  label,
  placeholder,
  searchIconUrl,
  action,
}: SearchInputProps) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const isIconUrlDefined =
    searchIconUrl &&
    searchIconUrl?.length > 0 &&
    new URL(searchIconUrl).pathname.split('.').pop() === 'svg'

  const Icon = () => {
    return isIconUrlDefined ? (
      <ReactSVG
        src={searchIconUrl}
        className={clsx(
          'search__button__icon',
          'search__button__icon--svg',
          classes.btnIconSvg
        )}
        wrapper="span"
      />
    ) : (
      <Search className={classes.searchIconSvg} />
    )
  }

  const inputPlaceholder = prismicText(
    placeholder,
    t('texts:general.search_bar.input_placeholder')
  )

  return (
    <div className={classes.root}>
      <Typography variant={'h3'} component={'h1'}>
        <CMSText
          data={label}
          defaultText={t('texts:general.search_bar.input_label')}
        />
      </Typography>
      <div className={clsx('search_input_container', classes.searchInput)}>
        <InputBase
          placeholder={inputPlaceholder}
          value={search}
          autoFocus={true}
          onChange={({ target }) => setSearch(target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              // Prevent the default behavior of the "Enter" event
              event.preventDefault()
              action()
            }
          }}
          inputProps={{ 'aria-label': 'search input' }}
        />
        {search.length > 0 && (
          <IconButton
            color="primary"
            className={clsx('clear_input_icon_button', classes.clearIcon)}
            aria-label="search"
            size="small"
            onClick={() => setSearch('')}
          >
            <Cancel style={{ fontSize: 15 }} />
          </IconButton>
        )}
        <IconButton
          color="primary"
          className={clsx('search_input_icon_button', classes.searchIcon)}
          aria-label="search"
          onClick={action}
        >
          <Icon />
        </IconButton>
      </div>
    </div>
  )
}

export default SearchInput
