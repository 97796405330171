import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Collapse,
} from '@material-ui/core'
import Link from '../Link/Link'
import { connect } from 'react-redux'
import InfoIcon from '@material-ui/icons/Info'
import dayjs from 'dayjs'
import { useRouter } from 'next/router'
import CMSText from '../Global/CMSText'
import Price from './Price'
import BasketShowDetails from './BasketShowDetails'
import { isClassicSub, isFlex } from '../../utils/constants'
import { findCurrentPlan } from '../../utils/utils'
import { RootState } from '../../redux/reducers/rootReducer'
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperShipping: {
      margin: 0,
      padding: theme.spacing(1, 2),
    },
    row: {
      display: 'flex',
      padding: theme.spacing(1, 0.5),
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    rowBorder: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1, 0.5),
      borderTop: `1px solid ${theme.extraColors.greyLight}`,
    },
    giftCardExplanation: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 10,
      padding: theme.spacing(0.4),
      borderRadius: theme.shape.borderRadius,
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
    giftCardIcon: {
      fontSize: 14,
      marginRight: theme.spacing(0.4),
    },
    rowHeavyBorder: {
      borderTopColor: theme.extraColors.grey,
    },
    rowNoBorder: {
      border: 0,
    },
    rowHeavyBorderBottom: {
      borderTop: 0,
      borderBottom: `1px solid ${theme.extraColors.grey}`,
    },
    shipping: {
      fontSize: '80%',
      fontWeight: 400,
      color: `${theme.extraColors.greyText}`,
    },
    left: {
      display: 'flex',
      flexDirection: 'row',
    },
    right: {
      whiteSpace: 'nowrap',
    },
    green: {
      color: theme.palette.success.main,
    },
    linkToAccount: {
      textDecoration: 'underline',
      fontSize: 10,
    },
    engagement: {
      color: theme.palette.primary.main,
      lineHeight: 1.2,
    },
    priceMonth: {
      margin: theme.spacing(0, 2, 0, 0),
    },
    titleIcon: {
      display: 'flex',
      gap: theme.spacing(1),
      margin: theme.spacing(0, 0, 1),
      alignItems: 'center',
    },
    marginRight: {
      marginRight: 10,
    },
    center: {
      textAlign: 'center',
      width: '100%',
    },
  })
)

const mapStateToProps = (state: RootState) => ({
  contract: state.contract,
  cart: state.cart,
  promotions: state.promotions,
})

const mapDispatchToProps = {}

type Tprops = ReturnType<typeof mapStateToProps> & {
  basket: any
  t: any
  settings: any
  showDetailsOnBuyAbleProducts: boolean
  isBuyAbleProducts: boolean
  mixedBasket: boolean
  numberOfDaysLeftInCurrentSubscription: number
  user: any
}

const BasketTotalsSub = ({
  basket,
  t,
  cart,
  contract,
  isBuyAbleProducts,
  showDetailsOnBuyAbleProducts,
  numberOfDaysLeftInCurrentSubscription = 30,
  promotions,
  mixedBasket,
  settings,
  user,
}: Tprops) => {
  const classes = useStyles()
  const [totalCurrent, setTotalCurrent] = useState(0)
  const router = useRouter()
  const isEndFunnel =
    router.route === '/shipping' || router.route === '/payment'
  const notDisplayWithoutShipping = basket?.data?.not_display_without_shipping
  const displayFlexPrice = settings?.display_flex_price_a_day
  const [showDetails, setShowDetails] = useState(true)

  useEffect(() => {
    if (isFlex) {
      if (
        contract?.items?.length > 0 &&
        contract?.items[0]?.orders &&
        contract?.items[0]?.orders?.length > 0
      ) {
        setTotalCurrent(parseFloat(`${contract?.items[0].total}`))
      } else {
        setTotalCurrent(0)
      }
    } else {
      let userSubscriptionQuantity = 0

      // mapping orders to calcul the subscription price and to create a new array wich contain every product row in the orders
      contract?.items?.length > 0 &&
        contract?.items[0]?.orders?.map((order) => {
          if (
            dayjs().diff(dayjs(order.startLeasingDate), 'day') <=
              dayjs().daysInMonth() &&
            order.paymentState !== 'paid' &&
            order.paymentState !== 'refunded' &&
            order.paymentState !== 'cancelled' &&
            order.state !== 'cart'
          ) {
            order?.items?.length > 0 &&
              order?.items?.map((product) => {
                product.units?.map((unit) => {
                  if (!unit.isReturned) {
                    product['orderToken'] = order.tokenValue
                    userSubscriptionQuantity++
                  }
                })
              })
          }
        })
      if (promotions?.items?.length > 0) {
        const currentSubscriptionPrice = 0
        const planPrice = findCurrentPlan(
          promotions,
          userSubscriptionQuantity,
          currentSubscriptionPrice
        )
        setTotalCurrent(planPrice)
      }
    }
  }, [contract, cart?.item?.items])
  return (
    <>
      <div className={classes.wrapperShipping}>
        {!isBuyAbleProducts && showDetailsOnBuyAbleProducts && (
          <>
            <BasketShowDetails
              t={t}
              basket={basket}
              setShowDetails={setShowDetails}
              showDetails={showDetails}
            />
            <Collapse in={showDetails} timeout="auto" unmountOnExit>
              <div>
                {/* Do we have a current subscription ? */}
                {contract?.items?.length > 0 &&
                  contract?.items[0]?.orders?.length > 0 &&
                  totalCurrent !== 0 &&
                  isClassicSub && (
                    <div className={classes.row}>
                      <div>
                        <Typography variant={'body2'}>
                          <CMSText
                            asText
                            data={basket?.data?.current_subscription}
                            defaultText={t('texts:basket:current_subscription')}
                          />
                        </Typography>
                        <Typography
                          variant={'body2'}
                          className={classes.linkToAccount}
                        >
                          <Link href={'/account'}>
                            <a>
                              <CMSText
                                asText
                                data={basket?.data?.check_on_account}
                                defaultText={t('texts:basket:check_on_account')}
                              />
                            </a>
                          </Link>
                        </Typography>
                      </div>
                      <div className={classes.left}>
                        <Typography variant={'body2'}>
                          <b>
                            <Price
                              amount={totalCurrent}
                              dayOrMonth={settings?.day_or_month}
                              dayOrMonthShort={settings?.day_or_month_short}
                              showDayOrMonth={false}
                              isShort={false}
                              displayFlexPrice={false}
                            />
                          </b>
                        </Typography>
                      </div>
                    </div>
                  )}
                {/* Current cart price (and duration if we're not in subscription) */}
                {isFlex && !isBuyAbleProducts && basket?.data?.enable_duration && (
                  <div className={classes.rowBorder}>
                    <Typography variant={'body2'} component="div">
                      <CMSText
                        asText
                        data={basket?.data?.duration}
                        defaultText={t('texts:basket:duration')}
                      />
                      <Typography
                        variant={'body2'}
                        className={classes.engagement}
                      >
                        <CMSText
                          asText
                          data={basket?.data?.sub_engagement}
                          defaultText={t('texts:basket:sub_engagement')}
                        />
                      </Typography>
                    </Typography>
                    <div className={classes.left}>
                      <Typography variant={'body2'}>
                        <b>
                          <Price
                            amount={
                              cart?.item?.totals?.totalWithoutBoughtProducts
                            }
                            dayOrMonth={settings?.day_or_month}
                            dayOrMonthShort={settings?.day_or_month_short}
                            showDayOrMonth
                            isShort={false}
                            displayFlexPrice={displayFlexPrice}
                          />
                        </b>
                      </Typography>
                    </div>
                  </div>
                )}
                {/* Assurance */}
                {basket?.data?.enable_assurance && (
                  <div className={classes.rowBorder}>
                    <Typography variant={'body2'}>
                      <CMSText
                        asText
                        data={basket?.data?.assurance}
                        defaultText={t('texts:basket:assurance')}
                      />
                    </Typography>
                    <div className={classes.left}>
                      <Typography variant={'body2'}>
                        <b>
                          <CMSText
                            asText
                            data={basket?.data?.free}
                            defaultText={t('texts:basket:free')}
                          />
                        </b>
                      </Typography>
                    </div>
                  </div>
                )}
                {/* If we are at the shipping state, we show the price of shipping */}
                {basket?.data?.enable_shipping && (
                  <>
                    {isEndFunnel && (
                      <div className={classes.rowBorder}>
                        <Typography variant={'body2'}>
                          <CMSText
                            asText
                            data={basket?.data?.shipping}
                            defaultText={t('texts:basket:shipping')}
                          />
                        </Typography>
                        <div className={classes.left}>
                          <Typography variant={'body2'}>
                            <b>
                              {cart.item.totals.shipping === 0 ? (
                                <CMSText
                                  asText
                                  data={basket?.data?.free}
                                  defaultText={t('texts:basket:free')}
                                />
                              ) : (
                                <>
                                  <Price
                                    amount={cart?.item?.totals?.shipping}
                                    showDayOrMonth={false}
                                    dayOrMonth={settings?.day_or_month}
                                    dayOrMonthShort={
                                      settings?.day_or_month_short
                                    }
                                  />
                                </>
                              )}
                            </b>
                          </Typography>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {/* Promotion */}
                {basket?.data?.enable_reduction && (
                  <div className={classes.rowBorder}>
                    <Typography variant={'body2'}>
                      <CMSText
                        asText
                        data={basket?.data?.reduction}
                        defaultText={t('texts:basket:reduction')}
                      />
                    </Typography>
                    <div className={classes.left}>
                      <Typography variant={'body2'}>
                        <b>
                          {cart?.item?.totals?.promotion ? (
                            <>
                              <Price
                                amount={cart?.item?.totals?.promotion}
                                dayOrMonth={settings?.day_or_month}
                                dayOrMonthShort={settings?.day_or_month_short}
                                showDayOrMonth={false}
                              />
                            </>
                          ) : (
                            <>
                              <CMSText
                                asText
                                data={basket?.data?.free}
                                defaultText={t('texts:basket:free')}
                              />
                            </>
                          )}
                        </b>
                      </Typography>
                    </div>
                  </div>
                )}
                {!isBuyAbleProducts && (
                  <div className={classes.rowBorder}>
                    <Typography variant={'body2'}>
                      <CMSText
                        asText
                        data={basket?.data?.return_date}
                        defaultText={t('texts:basket:return_date')}
                      />
                    </Typography>
                    <div className={classes.left}>
                      <Typography variant={'body2'} align={'right'}>
                        <b>
                          <CMSText
                            asText
                            data={basket?.data?.whenever_you_want}
                            defaultText={t('texts:basket:whenever_you_want')}
                          />
                        </b>
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            </Collapse>
            {/* Gift Card */}
            {user?.balance?.balance > 0 && (
              <>
                <div className={classes.rowBorder}>
                  <Typography variant={'body2'}>
                    <CMSText
                      asText
                      data={basket?.data?.gift_card_text}
                      defaultText={t('texts:basket:gift_card_text')}
                    />
                  </Typography>
                  <div className={classes.left}>
                    <Typography
                      variant={'body2'}
                      className={clsx('basket__discount', classes.green)}
                    >
                      <b>
                        <Price
                          amount={user?.balance?.balance}
                          showDayOrMonth={false}
                          dayOrMonth={settings?.day_or_month}
                          dayOrMonthShort={settings?.day_or_month_short}
                        />
                      </b>
                    </Typography>
                  </div>
                </div>
                <div
                  className={clsx(
                    classes.giftCardExplanation,
                    'basket-giftCard-explanation'
                  )}
                >
                  <InfoIcon fontSize="small" className={classes.giftCardIcon} />
                  <CMSText
                    asText
                    data={basket?.data?.gift_card_explanation}
                    defaultText={t('texts:basket:gift_card_explanation')}
                  />
                </div>
              </>
            )}
          </>
        )}
        {/* Total */}
        {!isBuyAbleProducts ? (
          <>
            {isFlex ? (
              <div className={clsx(classes.rowBorder, classes.rowNoBorder)}>
                <Typography variant={'body2'}>
                  <b>
                    <>
                      <CMSText
                        asText
                        data={basket?.data?.total}
                        defaultText={t('texts:basket:total')}
                      />
                      &nbsp;
                      <CMSText
                        asText
                        data={basket?.data?.renttotal}
                        defaultText={t('texts:basket:rentTotal')}
                      />
                      &nbsp;
                      {!isEndFunnel && !notDisplayWithoutShipping && (
                        <span
                          className={clsx(classes.shipping, 'shipping__note')}
                        >
                          <CMSText
                            asText
                            data={basket?.data?.without_shipping}
                            defaultText={t('texts:basket:withoutShipping')}
                          />
                        </span>
                      )}
                    </>
                  </b>
                </Typography>
                <div className={classes.left}>
                  <Typography
                    variant={'body2'}
                    color={'secondary'}
                    className={'basket__total'}
                  >
                    <b>
                      <span>
                        <Price
                          amount={
                            cart?.item?.totals?.totalWithoutBoughtProducts
                          }
                          numberOfDays={numberOfDaysLeftInCurrentSubscription}
                          dayOrMonth={settings?.day_or_month}
                          dayOrMonthShort={settings?.day_or_month_short}
                          showDayOrMonth
                          displayFlexPrice={displayFlexPrice}
                        />
                        {cart?.item?.totals?.shipping !== 0 &&
                          isEndFunnel &&
                          !mixedBasket && (
                            <>
                              <span>&nbsp;+&nbsp;</span>
                              <span>
                                <Price
                                  amount={cart?.item?.totals?.shipping}
                                  dayOrMonth={settings?.day_or_month}
                                  dayOrMonthShort={settings?.day_or_month_short}
                                  showDayOrMonth={false}
                                  displayFlexPrice={displayFlexPrice}
                                />
                              </span>
                            </>
                          )}
                      </span>
                    </b>
                  </Typography>
                </div>
              </div>
            ) : (
              <div className={clsx(classes.rowBorder, classes.rowNoBorder)}>
                <>
                  {/* if futurPrice = 0, no promotion can match totalproduct number */}
                  {promotions?.futurPrice !== 0 ? (
                    <>
                      <Typography variant={'body2'}>
                        <b>
                          <CMSText
                            asText
                            data={basket?.data?.total_with_subscription}
                            defaultText={t(
                              'texts:basket:total_with_subscription'
                            )}
                          />
                          &nbsp;
                        </b>
                      </Typography>
                      <div className={classes.left}>
                        <Typography
                          variant={'body2'}
                          color={'secondary'}
                          className={'basket__total'}
                        >
                          <b>
                            <Price
                              amount={promotions?.futurPrice}
                              dayOrMonth={settings?.day_or_month}
                              dayOrMonthShort={settings?.day_or_month_short}
                              showDayOrMonth
                              displayFlexPrice={displayFlexPrice}
                            />
                            &nbsp;
                            {isEndFunnel && cart?.item?.totals?.shipping !== 0 && (
                              <>
                                +&nbsp;
                                <Price
                                  amount={cart?.item?.totals?.shipping}
                                  dayOrMonth={settings?.day_or_month}
                                  dayOrMonthShort={settings?.day_or_month_short}
                                  showDayOrMonth={false}
                                  displayFlexPrice={false}
                                />
                              </>
                            )}
                          </b>
                        </Typography>
                      </div>
                    </>
                  ) : (
                    <>
                      <Typography variant={'body2'} color={'error'}>
                        <b>
                          <CMSText
                            asText
                            data={basket?.data?.error_basket}
                            defaultText={t('texts:basket:error_basket')}
                          />
                          &nbsp;
                        </b>
                      </Typography>
                    </>
                  )}
                </>
              </div>
            )}
            {/* if we're in sub and there is a subscription active for the current user */}
            {contract?.items?.length > 0 &&
              contract?.items[0]?.orders?.length > 0 &&
              !mixedBasket &&
              isFlex && (
                <div className={classes.row}>
                  <div className={classes.priceMonth}>
                    <Typography variant={'body2'} className={classes.titleIcon}>
                      <InfoIcon />
                      <b>
                        <CMSText
                          asText
                          data={basket?.data?.to_pay_this_month}
                          defaultText={t('texts:basket:to_pay_this_month')}
                        />
                      </b>
                    </Typography>
                    <Typography
                      variant={'caption'}
                      component={'p'}
                      className={classes.engagement}
                    >
                      <CMSText
                        asText
                        data={basket?.data?.newarticles}
                        defaultText={t('texts:basket:newarticles')}
                      />
                      &nbsp;
                      {dayjs().format('L')}&nbsp;
                      <CMSText
                        asText
                        data={basket?.data?.to}
                        defaultText={t('texts:basket:to')}
                      />
                      &nbsp;
                      {cart.item.endLeasingDate}
                    </Typography>
                  </div>
                  <div className={classes.right}>
                    <Typography variant={'body2'} color={'secondary'}>
                      <b>
                        {isEndFunnel ? (
                          <Price
                            amount={
                              cart?.item?.totals?.totalWithoutBoughtProducts +
                              cart?.item?.totals?.shipping
                            }
                            dayOrMonth={settings?.day_or_month}
                            dayOrMonthShort={settings?.day_or_month_short}
                            showDayOrMonth
                            isShort={false}
                            numberOfDays={numberOfDaysLeftInCurrentSubscription}
                            displayFlexPrice={displayFlexPrice}
                          />
                        ) : (
                          <>
                            <Price
                              amount={
                                cart?.item?.totals?.totalWithoutBoughtProducts
                              }
                              dayOrMonth={settings?.day_or_month}
                              dayOrMonthShort={settings?.day_or_month_short}
                              showDayOrMonth
                              isShort={false}
                              numberOfDays={
                                numberOfDaysLeftInCurrentSubscription
                              }
                              displayFlexPrice={displayFlexPrice}
                            />
                            &nbsp;
                          </>
                        )}
                      </b>
                    </Typography>
                  </div>
                </div>
              )}
          </>
        ) : (
          <div
            className={clsx(
              classes.rowBorder,
              classes.rowHeavyBorder,
              !mixedBasket && classes.rowHeavyBorderBottom
            )}
          >
            <Typography variant={'body2'}>
              <b>
                <>
                  {!mixedBasket && isClassicSub && (
                    <CMSText
                      asText
                      data={basket?.data?.total}
                      defaultText={t('texts:basket:total')}
                    />
                  )}
                  <CMSText
                    asText
                    data={basket?.data?.renttotal}
                    defaultText={t('texts:basket:buyTotal')}
                  />
                </>
              </b>
            </Typography>
            <div className={classes.left}>
              <Typography
                variant={'body2'}
                color={'secondary'}
                className={'basket__total'}
              >
                <b>
                  {isEndFunnel ? (
                    <span>
                      <Price
                        amount={
                          isFlex
                            ? cart?.item?.totals?.total -
                              cart?.item?.totals.totalWithoutBoughtProducts -
                              cart?.item?.totals?.shipping
                            : cart?.item?.totals?.total -
                              cart?.item?.totals?.shipping
                        }
                        dayOrMonth={settings?.day_or_month}
                        dayOrMonthShort={settings?.day_or_month_short}
                        showDayOrMonth={false}
                        buyAble={true}
                        displayFlexPrice={displayFlexPrice}
                      />
                      {cart?.item?.totals?.shipping !== 0 && !mixedBasket && (
                        <>
                          <span>&nbsp;+&nbsp;</span>
                          <span>
                            <Price
                              amount={cart?.item?.totals?.shipping}
                              dayOrMonth={settings?.day_or_month}
                              dayOrMonthShort={settings?.day_or_month_short}
                              showDayOrMonth={false}
                              buyAble={true}
                            />
                          </span>
                        </>
                      )}
                    </span>
                  ) : (
                    <>
                      <Price
                        amount={
                          isFlex
                            ? cart?.item?.totals?.total -
                              cart?.item?.totals?.totalWithoutBoughtProducts -
                              cart?.item?.totals?.shipping
                            : cart?.item?.totals?.totalItemWithoutPromotion
                        }
                        dayOrMonth={settings?.day_or_month}
                        dayOrMonthShort={settings?.day_or_month_short}
                        showDayOrMonth={false}
                        isShort={false}
                        buyAble={true}
                        displayFlexPrice={displayFlexPrice}
                      />
                    </>
                  )}
                </b>
              </Typography>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(BasketTotalsSub)
