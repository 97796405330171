import { makeStyles, createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.down('md')]: {
        '& .MuiPaper-root': {
          width: '100%',
        },
      },
    },
    drawerLayout: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      [theme.breakpoints.up('lg')]: {
        width: theme.sizes.basketWidthLG,
      },
    },
    drawerHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1, 1, 1, 2),
      position: 'absolute',
      zIndex: theme.zIndex.appBar,
      top: 0,
      left: 0,
      right: 0,
      height: theme.sizes.headerHeight,
      background: theme.palette.common.white,
      [theme.breakpoints.down('md')]: {
        position: 'fixed',
      },
    },
    drawerClose: {
      '&:hover, &:focus': {
        background: 'none',
      },
    },
    drawerContent: {
      flexGrow: 1,
      marginTop: theme.sizes.headerHeight,
    },
    drawerFooter: {
      position: 'sticky',
      bottom: 0,
      boxShadow: theme.shadows[1],
    },
  })
)

export default useStyles
