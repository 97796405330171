import React, { useEffect, useState } from 'react'
import { makeStyles, createStyles, Typography } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import CMSText from '../Global/CMSText'

const useStyles = makeStyles(() =>
  createStyles({
    showMore: {
      textAlign: 'center',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    verticalAlign: {
      alignContent: 'center',
    },
  })
)

type Tprops = {
  t: any
  basket: any
  isBuyAbleProducts?: boolean
  setShowDetails: (boolean) => void
  showDetails: boolean
}

const BasketShowDetails = ({
  basket,
  t,
  isBuyAbleProducts,
  setShowDetails,
  showDetails,
}: Tprops) => {
  const classes = useStyles()
  const [showDetailsLink, setShowDetailsLink] = useState(true)

  useEffect(() => {
    setShowDetailsLink(
      (!isBuyAbleProducts && basket?.data?.enable_total_price_per_day) ||
        basket?.data?.enable_total_without_shipping ||
        basket?.data?.enable_total_weight ||
        (!isBuyAbleProducts && basket?.data?.enable_duration) ||
        basket?.data?.enable_assurance
    )
  })

  return (
    <>
      {showDetailsLink && (
        <div
          onClick={() => setShowDetails(!showDetails)}
          className={classes.showMore}
        >
          {!showDetails ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          <Typography
            variant={'caption'}
            component={'div'}
            className={classes.verticalAlign}
          >
            {!showDetails ? (
              <CMSText
                asText
                data={basket?.data?.showDetails}
                defaultText={t('texts:basket:showDetails')}
              />
            ) : (
              <CMSText
                asText
                data={basket?.data?.hideDetails}
                defaultText={t('texts:basket:hideDetails')}
              />
            )}
          </Typography>
        </div>
      )}
    </>
  )
}

export default BasketShowDetails
