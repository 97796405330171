import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { numberToPrice } from '../../utils/utils'
import { isSubscription, isFlex } from '../../utils/constants'
import CMSText from '../Global/CMSText'
import { useTheme } from '@material-ui/core'
import { RootState } from '../../redux/reducers/rootReducer'

type TProps = ReturnType<typeof mapStateToProps> & {
  amount: number
  isShort?: boolean
  showDayOrMonth: boolean
  buyAble?: boolean
  dayOrMonth?: []
  dayOrMonthShort?: []
  UnitText?: []
  UnitTextShort?: []
  amountNotDivided?: boolean
  numberOfDays: number
  displayFlexPrice?: boolean
  showUnitText?: boolean
}
const mapStateToProps = (state: RootState) => ({
  cart: state.cart,
})
const Price = ({
  amount,
  isShort,
  showDayOrMonth,
  buyAble,
  dayOrMonth,
  dayOrMonthShort,
  UnitText,
  UnitTextShort,
  amountNotDivided = false,
  numberOfDays = 30,
  cart,
  displayFlexPrice = false,
  showUnitText,
}: TProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  let currencySymbol = '€'
  let isRight = true
  switch (cart?.item?.currency) {
    case 'EUR':
      currencySymbol =
        theme?.settings?.priceFormat === 'comma_two_decimals' ? '\xa0€' : '€'
      isRight = true
      break
    case 'CHF':
      currencySymbol = 'CHF '
      isRight = false
      break
    case 'GBP':
      currencySymbol = '£'
      isRight = false
      break
    case 'USD':
      currencySymbol = '$'
      isRight = true
      break
    case 'AED':
      currencySymbol = 'AED'
      isRight = true
      break
    default:
      break
  }

  const altNumberFormat = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
  })

  const amountDisplay: number =
    isFlex && displayFlexPrice && !buyAble
      ? numberToPrice(amount, amountNotDivided) / numberOfDays
      : numberToPrice(amount, amountNotDivided)

  return (
    <>
      <span className={'price__amount'}>
        {!isRight && currencySymbol}
        <span>
          {theme?.settings?.priceFormat === 'comma_two_decimals'
            ? altNumberFormat.format(amountDisplay)
            : amountDisplay}
        </span>
        {isRight && currencySymbol}
      </span>
      {showDayOrMonth && !buyAble && (
        <span className={'price__duration'}>
          <>
            {!displayFlexPrice ? (
              <>
                <CMSText
                  asText
                  defaultText={
                    isSubscription
                      ? t('texts:general:month_short')
                      : t('texts:general:day_short')
                  }
                  data={isShort ? dayOrMonthShort : dayOrMonth}
                />
                {showUnitText && (
                  <CMSText
                    asText
                    defaultText={t('texts:general:unit')}
                    data={isShort ? UnitTextShort : UnitText}
                  />
                )}
              </>
            ) : (
              <>
                <span>{t('texts:general:day')}</span>
                {showUnitText && <span>{t('texts:general:unit')}</span>}
              </>
            )}
          </>
        </span>
      )}
    </>
  )
}
export default connect(mapStateToProps)(Price)
