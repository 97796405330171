import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles, Theme } from '@material-ui/core'

import Button from '../Button/Button'
import { Remove } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import Loader from '../Loader/Loader'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    quantitySelector: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
    btn: {
      padding: 0,
      minWidth: 32,
      width: 32,
      height: 32,
      borderRadius: theme.shape.borderRadius * 3,
    },
    btnLoading: {
      cursor: 'default',
      pointerEvents: 'none',
    },
    alignLeft: {
      justifyContent: 'flex-start',
    },
    isSmall: {
      paddingTop: 0,
      '& .MuiButtonBase-root': {
        minWidth: 22,
        width: 22,
        height: 22,
        '&>span>svg': {
          width: '.75em',
          height: '.75em',
        },
      },
      '& .quantitySelector__total': {
        height: 32,
        width: 35,
        margin: theme.spacing(0, 0.5),
      },
    },
    disableForcedMinus: {
      '&:disabled': {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        background: 'transparent',
      },
    },
    total: {
      position: 'relative',
      zIndex: 2,
      width: 50,
      height: 45,
      margin: theme.spacing(0, 1),
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      border: `1px solid ${theme.extraColors.grey}`,
      borderRadius: theme.shape.borderRadius,
      background: theme.palette.common.white,
      '.isSmall &': {
        width: 52,
      },
    },
    disableForcedPlus: {
      '&:disabled': {
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
      },
    },
    overlay: {
      zIndex: 3,
      position: 'absolute',
      top: -2,
      left: -2,
      right: -2,
      bottom: -2,
      background: 'rgba(255,255,255, .8)',
      backdropFilter: 'blur(2px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      pointerEvents: 'none',
      '&:hover': {
        cursor: 'wait',
        pointerEvents: 'none',
      },
    },
  })
)

type Tprops = {
  value: number
  handleChange: (number: number) => void
  min?: number
  max?: number
  disable?: boolean
  small?: boolean
  isShop?: boolean
  align?: string
  loading?: boolean
}

const QuantitySelector = ({
  value,
  handleChange,
  min = 0,
  max,
  small,
  isShop,
  disable,
  align,
  loading,
}: Tprops) => {
  const classes = useStyles()
  return (
    <div
      className={clsx('quantitySelector', classes.quantitySelector, {
        [classes.isSmall]: small,
        [classes.alignLeft]: align === 'left',
      })}
    >
      {loading &&
        (small ? (
          <div className={classes.overlay} />
        ) : (
          <div className={classes.overlay}>
            <Loader />
          </div>
        ))}
      {!loading ? (
        <Button
          className={clsx(
            'quantitySelector__btn',
            'quantitySelector__btn--minus',
            classes.btn,
            isShop && classes.disableForcedMinus
          )}
          data-testid="removeQuantity"
          variant="outlined"
          color="primary"
          type="button"
          disabled={value === min || disable}
          onClick={() => {
            if (value !== min) {
              handleChange(--value)
            }
          }}
        >
          <Remove />
        </Button>
      ) : (
        <span
          className={clsx(
            'MuiButtonBase-root',
            classes.btn,
            classes.btnLoading
          )}
        ></span>
      )}
      <div
        className={clsx('quantitySelector__total', classes.total, {
          [(classes.isSmall, 'quantitySelector__total--small')]: small,
        })}
      >
        <span>{value}</span>
      </div>
      {!loading ? (
        <Button
          className={clsx(
            'quantitySelector__btn',
            'quantitySelector__btn--plus',
            classes.btn,
            isShop && classes.disableForcedPlus
          )}
          data-testid="addQuantity"
          variant="outlined"
          color="primary"
          type="button"
          disabled={!!(max && value === max) || disable}
          onClick={() => {
            if (value !== max) {
              handleChange(++value)
            }
          }}
        >
          <AddIcon />
        </Button>
      ) : (
        <span
          className={clsx(
            'MuiButtonBase-root',
            classes.btn,
            classes.btnLoading
          )}
        ></span>
      )}
    </div>
  )
}

export default QuantitySelector
