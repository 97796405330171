import React from 'react'
import clsx from 'clsx'
import {
  createStyles,
  Dialog,
  DialogContent,
  makeStyles,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Button from '../Button/Button'
import ErrorIcon from '@material-ui/icons/Error'
import { deleteChosenStore } from '../../redux/actions/inStore'
import { connect } from 'react-redux'
import CMSText from '../Global/CMSText'
import { useRouter } from 'next/router'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiDialog-paper': {
        borderRadius: theme.shape.borderRadius,
      },
    },
    container: {},
    modalHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: theme.extraColors.greyLight,
    },
    cross: {
      cursor: 'pointer',
      height: 32,
      width: 32,
      padding: theme.spacing(0.5),
      color: theme.palette.common.white,
      backgroundColor: theme.extraColors.grey,
      borderRadius: '50%',
      margin: theme.spacing(1),
    },
    title: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
    row: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
      gap: theme.spacing(1),
      '& .MuiTypography-root>.paragraph-class': {
        marginTop: 0,
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    marginLeft: {
      marginLeft: theme.spacing(2),
    },
    warning: {},
    warningText: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '80%',
      },
    },
  })
)

const mapDispatchToProps = {
  deleteChosenStore: deleteChosenStore,
}

const mapStateToProps = () => ({})

type Tprops = {
  open: boolean
  handleClose: any
  doc: any
  t: any
  deleteChosenStore: (data) => void
}

const ChangeStoreModal = ({
  open,
  handleClose,
  deleteChosenStore,
  doc,
  t,
}: Tprops) => {
  const classes = useStyles()
  const router = useRouter()

  const closeModalAndDeleteStore = async () => {
    // close modale, delete store, delete cart
    handleClose()
    //await deleteCart(cart.item.tokenValue)
    await localStorage.removeItem('selectedStore')
    //await localStorage.removeItem('CartToken')
    await deleteChosenStore(router.asPath)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'sm'}
        className={classes.root}
        scroll={'paper'}
        disableBackdropClick
      >
        <div className={classes.container}>
          <div className={`${classes.modalHeader}`}>
            <Typography
              variant="h5"
              component="h2"
              className={`${classes.title}`}
            >
              <CMSText
                asText
                data={doc?.data?.change_store_title}
                defaultText={t('texts:instore:change_store_title')}
              />
            </Typography>
            <CloseIcon className={classes.cross} onClick={handleClose} />
          </div>
          <DialogContent dividers>
            <div className={clsx(classes.row, classes.warning)}>
              <ErrorIcon color={'error'} />
              <Typography
                className={clsx(classes.row, classes.warningText)}
                component={'div'}
              >
                <CMSText
                  data={doc?.data?.change_store_paragraph}
                  defaultText={t('texts:instore:change_store_paragraph')}
                />
              </Typography>
            </div>
            <div className={classes.row}>
              <Button onClick={handleClose}>
                <CMSText
                  asText
                  data={doc?.data?.cancel_button}
                  defaultText={t('texts:instore:cancel_button')}
                />
              </Button>
              <Button
                variant={'contained'}
                color={'secondary'}
                className={classes.marginLeft}
                onClick={closeModalAndDeleteStore}
              >
                <CMSText
                  asText
                  data={doc?.data?.change_store_button}
                  defaultText={t('texts:instore:change_store_button')}
                />
              </Button>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeStoreModal)
