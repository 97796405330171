import { useState } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

export const useScrollState = () => {
  const [yPos, setYPos] = useState(0)
  const [showOnScrollUp, setShowOnScrollUp] = useState(false)
  const [showOnTop, setShowOnTop] = useState(true)
  const [showAboveFooter, setShowAboveFooter] = useState(true)

  useScrollPosition(
    ({ prevPos, currPos }) => {
      setYPos(currPos.y)
      const isScrollUp = currPos.y < prevPos.y
      let isTop = false
      if (currPos.y >= 0 && currPos.y < 100) {
        isTop = true
      }
      if (isScrollUp !== showOnScrollUp) setShowOnScrollUp(isScrollUp)
      isTop ? setShowOnTop(isTop) : setShowOnTop(false)

      const basketPosition = document.getElementById('basket')
      let topBasket = 0
      if (!basketPosition) {
        return
      }
      const boundingClientRect = basketPosition.getBoundingClientRect()
      if (boundingClientRect) {
        topBasket = boundingClientRect.top
      }

      const bodyWithoutFooterHeight =
        document.body.clientHeight -
        document.getElementById('basket_visibility_threshold').offsetHeight
      if (currPos.y - topBasket >= -bodyWithoutFooterHeight) {
        setShowAboveFooter(true)
      } else {
        setShowAboveFooter(false)
      }
    },
    [showOnScrollUp, showOnTop, yPos, showAboveFooter],
    null,
    false,
    150
  )
  return {
    showOnScrollUp,
    showOnTop,
    yPos,
    showAboveFooter,
  }
}
