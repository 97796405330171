import React, { useState } from 'react'
import clsx from 'clsx'
import {
  makeStyles,
  createStyles,
  Theme,
  ListItem,
  Slide,
  List,
} from '@material-ui/core'
import CMSText from '../Global/CMSText'
import Button from '../Button/Button'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { LayoutStyle } from '../../typings/base'
import Link from '../Link/Link'

type TProps = {
  general: any
  t: any
  type?: string
  isMobile?: boolean
  LayoutStyle?: LayoutStyle
}

const useStyles = makeStyles<Theme, LayoutStyle>((theme: Theme) =>
  createStyles({
    root: {},
    rootMobile: {
      borderTop: `1px solid ${theme.extraColors.grey}`,
    },
    hide: {
      height: 0,
      opacity: 0,
      width: 0,
      overflow: 'hidden',
    },
    menuList: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
      color: 'currentColor',
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        gap: theme.spacing(1),
      },
    },
    menuListMobileSecondary: {
      padding: theme.spacing(1),
      backgroundColor: theme.extraColors.greyLight,
    },
    menuItem: {
      position: 'relative',
      display: 'inline-block',
      lineHeight: 1.2,
      fontSize: theme.typography.caption.fontSize,
      textAlign: 'left',
      color: 'currentColor',
      [theme.breakpoints.up('lg')]: {
        '&:not(:last-child)': {
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),
          '&:after': {
            content: '"|"',
          },
        },
      },
      '&:hover, &:focus': {
        '& .menu__submenu': {
          display: 'block',
        },
      },
    },
    menuLink: {
      color: (LayoutStyle) =>
        !LayoutStyle.isTransparent
          ? LayoutStyle.color
            ? LayoutStyle.color
            : theme.palette.common.black
          : theme.palette.common.white,
      '&:hover, &:focus': {
        color: (LayoutStyle) =>
          !LayoutStyle.isTransparent
            ? LayoutStyle.hover
              ? LayoutStyle.hover.color
              : theme.palette.common.black
            : theme.palette.common.white,
      },
    },
    subMenu: {
      position: 'absolute',
      display: 'none',
      minWidth: 200,
      textAlign: 'left',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: (LayoutStyle) =>
        LayoutStyle.submenuBackgroundColor || theme.palette.common.white,
      boxShadow: theme.shadows[1],
    },
    subMenuItem: {
      listStyle: 'none',
      margin: '0.7rem 0',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    subMenuLink: {
      color: (LayoutStyle) =>
        LayoutStyle.submenuTextColor || theme.palette.common.black,
      '&:hover, &:focus': {
        color: (LayoutStyle) =>
          LayoutStyle.hover
            ? LayoutStyle.hover.color
            : theme.palette.common.black,
      },
    },
    menuMobileItem: {
      cursor: 'pointer',
      padding: 0,
      color: (LayoutStyle) =>
        LayoutStyle.color ? LayoutStyle.color : theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      justifyContent: 'space-between',
      borderBottom: `1px solid ${theme.extraColors.greyLight}`,
      '&:active': {
        backgroundColor: theme.extraColors.greyLight,
      },
    },
    menuMobileItemSecondary: {
      fontSize: theme.typography.caption.fontSize,
      color: theme.extraColors.greyText,
    },
    menuMobileLink: {
      flex: 1,
      padding: theme.spacing(1, 2),
      color: (LayoutStyle) =>
        LayoutStyle.color ? LayoutStyle.color : theme.palette.common.black,
    },
    subMenuMobile: {
      '& .MuiList-root': {
        padding: 0,
      },
    },
    subMenuMobileFixed: {
      position: 'relative',
      '& .MuiList-root': {
        paddingTop: theme.sizes.headerHeight / 2,
        paddingBottom: 0,
      },
    },
    subMenuMobileBack: {
      display: 'flex',
      alignItems: 'center',
      height: theme.sizes.headerHeight / 2,
      padding: 0,
      backgroundColor: theme.extraColors.grey,
    },
    subMenuMobileBackFixed: {
      position: 'fixed',
      zIndex: 1,
      top: theme.sizes.headerHeight,
      left: 0,
      right: 0,
    },
    subMenuMobileBackBtn: {
      justifyContent: 'flex-start',
      height: '100%',
      borderRadius: 0,
    },
  })
)

const Menu = ({ general, t, type, isMobile, LayoutStyle }: TProps) => {
  const classes = useStyles(LayoutStyle)
  const slices = general?.data?.body
  const mainMenu =
    type === 'primary'
      ? slices?.filter((slice) => slice.slice_type === 'main_menu_item')
      : slices?.filter((slice) => slice.slice_type === 'secondary_menu_item')
  const [subMenuParent, setSubMenuParent] = useState(null)
  const [subMenu, setSubMenu] = useState([])
  const [subMenuOpen, setSubMenuOpen] = useState(false)
  const [subMenuStyle, setSubMenuStyle] = useState(false)

  const handleSubMenu = (parent, data) => {
    setSubMenuParent(parent)
    setSubMenu(data)
    setSubMenuOpen(true)
    setTimeout(() => {
      setSubMenuStyle(true)
    }, 300)
  }

  const handleBack = () => {
    setSubMenu([])
    setSubMenuOpen(false)
    setSubMenuStyle(false)
  }

  return (
    <div
      className={clsx(
        classes.root,
        `menu`,
        `menu--${type}`,
        isMobile && [
          classes.rootMobile,
          `menu--mobile`,
          `menu--${type}`,
          `menu--mobile--${type}`,
        ]
      )}
    >
      <div className={clsx(subMenuOpen && classes.hide)}>
        {mainMenu?.length > 0 && (
          <nav>
            <ul
              className={clsx(
                classes.menuList,
                'menu__list',
                `menu__list--${type}`,
                isMobile && `menu__list--mobile`,
                isMobile &&
                  type === 'secondary' && [classes.menuListMobileSecondary]
              )}
            >
              {mainMenu?.map((item, index) => {
                const navLinkLabel = item?.primary?.['nav_link_label']
                const navLink = item?.primary?.['nav_link']
                return (
                  navLinkLabel?.length > 0 &&
                  navLink?.link_type !== 'Any' &&
                  (isMobile ? (
                    item.items?.length > 1 ? (
                      <ListItem
                        key={index}
                        onClick={() => handleSubMenu(navLinkLabel, item.items)}
                        className={clsx(
                          'menu-mobile__item',
                          `menu-mobile__item--${type}`,
                          classes.menuMobileItem
                        )}
                      >
                        <span className={classes.menuMobileLink}>
                          <CMSText
                            asText
                            data={navLinkLabel}
                            defaultText={t('texts:general:main_menu:item')}
                          />
                        </span>
                        <KeyboardArrowRight />
                      </ListItem>
                    ) : (
                      <ListItem
                        key={index}
                        className={clsx(
                          classes.menuMobileItem,
                          'menu-mobile__item',
                          `menu-mobile__item--${type}`,
                          type === 'secondary' &&
                            classes.menuMobileItemSecondary
                        )}
                      >
                        <Link href={navLink.url}>
                          <a
                            className={classes.menuMobileLink}
                            target={
                              navLink?.target === '_blank' ? '_blank' : '_self'
                            }
                          >
                            <CMSText
                              asText
                              data={navLinkLabel}
                              defaultText={t('texts:general:main_menu:item')}
                            />
                          </a>
                        </Link>
                      </ListItem>
                    )
                  ) : (
                    <div
                      key={index}
                      className={clsx(classes.menuItem, 'menu__item')}
                    >
                      <li key={index}>
                        <Link href={navLink.url}>
                          <a
                            className={clsx(classes.menuLink, 'menu__link')}
                            target={
                              navLink?.target === '_blank' ? '_blank' : '_self'
                            }
                          >
                            <CMSText
                              asText
                              data={navLinkLabel}
                              defaultText={t('texts:general:main_menu:item')}
                            />
                          </a>
                        </Link>
                        {item.items?.length > 1 && (
                          <div
                            className={clsx(classes.subMenu, 'menu__submenu')}
                          >
                            <ul>
                              {item.items.map((subItem, subIndex) => {
                                const subNavLinkLabel =
                                  subItem?.['sub_nav_link_label']
                                const subNavLink = subItem?.['sub_nav_link']

                                return (
                                  subNavLinkLabel?.length > 0 &&
                                  subNavLink?.link_type !== 'Any' && (
                                    <li
                                      className={classes.subMenuItem}
                                      key={subIndex}
                                    >
                                      <Link href={subNavLink?.url}>
                                        <a
                                          target={
                                            subNavLink?.target === '_blank'
                                              ? '_blank'
                                              : '_self'
                                          }
                                          className={classes.subMenuLink}
                                        >
                                          <CMSText
                                            asText
                                            data={subNavLinkLabel}
                                            defaultText={t(
                                              'texts:general:main_menu:item'
                                            )}
                                          />
                                        </a>
                                      </Link>
                                    </li>
                                  )
                                )
                              })}
                            </ul>
                          </div>
                        )}
                      </li>
                    </div>
                  ))
                )
              })}
            </ul>
          </nav>
        )}
      </div>
      <Slide in={subMenuOpen} direction="left">
        <div className={clsx(!subMenuOpen && classes.hide)}>
          <div
            className={clsx(
              classes.subMenuMobile,
              subMenuStyle && classes.subMenuMobileFixed
            )}
          >
            <List>
              <ListItem
                className={clsx(
                  'menu-mobile__back',
                  classes.subMenuMobileBack,
                  subMenuStyle && classes.subMenuMobileBackFixed
                )}
              >
                <Button
                  size="small"
                  onClick={() => handleBack()}
                  startIcon={<KeyboardArrowLeft />}
                  className={classes.subMenuMobileBackBtn}
                  fullWidth
                  disableRipple
                  disableTouchRipple
                  disableElevation
                >
                  <CMSText
                    asText
                    data={subMenuParent}
                    defaultText={t('texts:general:main_menu:item')}
                  />
                </Button>
              </ListItem>
              {subMenu.map((item, index) => {
                const subNavLinkLabel = item?.['sub_nav_link_label']
                const subNavLink = item?.['sub_nav_link']

                return (
                  subNavLinkLabel?.length > 0 &&
                  subNavLink?.link_type !== 'Any' && (
                    <ListItem key={index} className={classes.menuMobileItem}>
                      <Link href={subNavLink?.url}>
                        <a
                          target={
                            subNavLink?.target === '_blank' ? '_blank' : '_self'
                          }
                          onClick={() => setSubMenu([])}
                          className={classes.menuMobileLink}
                        >
                          <CMSText
                            asText
                            data={subNavLinkLabel}
                            defaultText={t('texts:general:main_menu:item')}
                          />
                        </a>
                      </Link>
                    </ListItem>
                  )
                )
              })}
            </List>
          </div>
        </div>
      </Slide>
    </div>
  )
}

export default Menu
