import React from 'react'
import { makeStyles, createStyles, Theme, Typography } from '@material-ui/core'
import { Cart } from '../../typings/base'
import CMSText from '../Global/CMSText'
import Price from './Price'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperShipping: {
      margin: 0,
      padding: theme.spacing(1),
    },
    rowBorder: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1, 0.5),
      borderTop: `1px solid ${theme.extraColors.greyLight}`,
    },
    left: {
      display: 'flex',
      flexDirection: 'row',
    },
    rowNoBorder: {
      border: 0,
    },
    row: {
      display: 'flex',
      padding: theme.spacing(1, 0.5),
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  })
)

type Tprops = {
  cart?: Cart
  t: any
  basket: any
  settings?: any
  user?: any
}

const BasketTotalsSecondHand = ({
  t,
  basket,
  cart,
  // user,
  settings,
}: Tprops) => {
  const basketSecondHandClasses = useStyles()
  return (
    <div className={basketSecondHandClasses.wrapperShipping}>
      <div className={basketSecondHandClasses.rowBorder}>
        <Typography variant={'body2'}>
          <CMSText
            asText
            data={basket?.data?.voucher_text_explication}
            defaultText={t('texts:basket:reduction_text_explication')}
          />
        </Typography>
      </div>
      {/* Total price without shipping */}
      <div className={basketSecondHandClasses.row}>
        <Typography variant={'body2'}>
          <CMSText
            asText
            data={basket?.data?.total_without_shipping}
            defaultText={t('texts:basket:total_without_shipping')}
          />
        </Typography>
        <div className={basketSecondHandClasses.left}>
          <Typography variant={'body2'}>
            <b>
              <Price
                amount={cart?.item?.totals?.totalSecondHand}
                showDayOrMonth={false}
                dayOrMonth={settings?.day_or_month}
                dayOrMonthShort={settings?.day_or_month_short}
              />
            </b>
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default BasketTotalsSecondHand
