import { useTranslation } from 'react-i18next'
import { prismicText } from '../components/Global/CMSText'
import { useCallback } from 'react'

export function useTakeback() {
  const { t } = useTranslation()

  // object to handle take back states
  const productCheckState = useCallback((data) => {
    return {
      perfectCondition: {
        title: prismicText(data?.perfectcondition, t('texts:takeBack:state_1')),
        desc: prismicText(
          data?.perfectconditiondefinition,
          t('texts:takeBack:state_1')
        ),
      },
      veryGoodCondition: {
        title: prismicText(
          data?.verygoodcondition,
          t('texts:takeBack:state_2')
        ),
        desc: prismicText(
          data?.verygoodconditiondefinition,
          t('texts:takeBack:state_2')
        ),
      },
      goodCondition: {
        title: prismicText(data?.goodcondition, t('texts:takeBack:state_3')),
        desc: prismicText(
          data?.goodconditiondefinition,
          t('texts:takeBack:state_3')
        ),
      },
    }
  }, [])

  // object to handle take back product status
  const productCheckStatus = useCallback((data) => {
    return {
      ACCEPTED: prismicText(
        data?.accepted_status,
        t('texts:takeBack:accepted_status')
      ),
      REFUSED: prismicText(
        data?.refused_status,
        t('texts:takeBack:refused_status')
      ),
      WAITING: prismicText(
        data?.in_progress_status,
        t('texts:takeBack:in_progress_status')
      ),
    }
  }, [])

  return {
    productCheckState,
    productCheckStatus,
  }
}
