import React from 'react'
import { IconButton } from '@mui/material'
import { ReactSVG } from 'react-svg'
import clsx from 'clsx'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import CMSText from '../Global/CMSText'
import { LayoutStyle } from '../../typings/base'
import Button from './Button'

const useStyles = makeStyles<Theme, LayoutStyle>((theme: Theme) => {
  const color = (LayoutStyle) =>
    !LayoutStyle.isTransparent &&
    (LayoutStyle.backgroundColor && LayoutStyle.color
      ? LayoutStyle.color
      : theme.palette.common.black)

  return createStyles({
    button: {
      fontWeight: theme.typography.body2.fontWeight,
      color,
      borderColor: color,
    },
    transparentButton: {
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
    },
    btnIcon: {
      padding: 0,
      marginLeft: theme.spacing(1),
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& .search__button__icon': {
        color,
        opacity: 1,
      },
    },
    btnIconSvg: {
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
      color,
      opacity: (LayoutStyle) => (LayoutStyle.isTransparent ? 1 : 0.5),
      lineHeight: 1,
      '& .injected-svg': {
        display: 'inline-block',
        width: '1em',
        height: '1em',
        fontSize: '1.5rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
      },
    },
  })
})

interface InputButtonProps {
  iconOnly?: boolean
  iconUrl?: string
  label?: any[]
  transparentBackground?: boolean
  onClick?: () => void
  isMobile?: boolean
  LayoutStyle: LayoutStyle
}

const InputButton = ({
  iconOnly = false,
  iconUrl,
  label,
  transparentBackground = false,
  onClick,
  isMobile,
  LayoutStyle,
  ...props
}: InputButtonProps) => {
  const { t } = useTranslation()
  const classes = useStyles(LayoutStyle)
  const isIconUrlDefined =
    iconUrl &&
    iconUrl?.length > 0 &&
    new URL(iconUrl).pathname.split('.').pop() === 'svg'

  const Icon = () => {
    return isIconUrlDefined ? (
      <ReactSVG
        src={iconUrl}
        className={clsx(
          'search__button__icon',
          'search__button__icon--svg',
          classes.btnIconSvg
        )}
        wrapper="span"
      />
    ) : (
      <Search />
    )
  }

  if (iconOnly || isMobile) {
    return (
      <IconButton
        {...props}
        className={clsx('search__button__icon__only', [
          classes.btnIcon,
          transparentBackground && 'search__button__icon__only__transparent',
        ])}
        disableRipple
        onClick={onClick}
      >
        <Icon />
      </IconButton>
    )
  }

  return (
    <Button
      size="small"
      variant={'outlined'}
      endIcon={<Icon />}
      className={clsx('search__button__icon', [
        transparentBackground && 'search__button__icon__transparent',
        transparentBackground && classes.transparentButton,
        classes.button,
      ])}
      disableRipple
      onClick={onClick}
    >
      <span>
        <CMSText
          asText
          data={label}
          defaultText={t('texts:general.search_bar.button_label')}
        />
      </span>
    </Button>
  )
}

export default InputButton
