export const addToCartUtility = (
  variantName,
  quantity,
  cart,
  parameters,
  product,
  isTryOut,
  stock
) => {
  const productAlreadyInCart = product !== false && product !== 'undefined'

  let totalNumberItems = 0
  cart?.item?.items?.map((product) => {
    totalNumberItems += product.quantity
  })

  const maxStock = product ? product?.product?.variants[0].maxStock : null

  const message = {
    message: 'error',
    variantName: variantName,
    productInCart: productAlreadyInCart,
  }

  if (!isTryOut) {
    if (productAlreadyInCart) {
      if (quantity === 0) {
        // delete
        message.message = 'delete'
      } else if (quantity > stock || quantity > maxStock) {
        // max in cart
        message.message = 'max'
      } else if (quantity < product.quantity) {
        // remove
        message.message = 'remove'
      } else if (quantity >= product.quantity) {
        // add
        if (
          parameters?.items?.maximumItemsInCart &&
          quantity > product.quantity &&
          totalNumberItems + quantity - product.quantity >
            parameters?.items?.maximumItemsInCart
        ) {
          message.message = 'limit'
        } else {
          message.message = 'add'
        }
      }
    } else {
      if (
        parameters?.items?.maximumItemsInCart &&
        totalNumberItems + quantity > parameters?.items?.maximumItemsInCart
      ) {
        message.message = 'limit'
      } else {
        message.message = 'add'
      }
    }
  } else {
    message.message = 'add tryout'
  }
  return message
}
