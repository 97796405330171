import React from 'react'
import { Typography, makeStyles, createStyles } from '@material-ui/core'
import CMSText from '../Global/CMSText'

type Tprops = {
  banner?: any
  t: any
}

const useStyles = makeStyles((theme) =>
  createStyles({
    banner: {
      height: 40,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      justifyContent: 'center',
      color: theme.palette.common.white,
      '& a': {
        color: theme.palette.common.white,
        textDecoration: 'underline',
      },
      [theme.breakpoints.down('md')]: {
        height: 60,
        padding: theme.spacing(2),
        textAlign: 'center',
      },
    },
  })
)
const Banner = ({ banner, t }: Tprops) => {
  const classes = useStyles()
  return (
    <div className={`banner ${classes.banner}`}>
      <Typography variant={'h5'} component={'div'}>
        {banner && (
          <CMSText
            data={banner.data.text}
            defaultText={t('texts:banner:text')}
          />
        )}
      </Typography>
    </div>
  )
}

export default Banner
