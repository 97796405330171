import { makeStyles, createStyles, Theme } from '@material-ui/core'
import { LayoutStyle } from '../../typings/base'

export const useBasketStyles = makeStyles<Theme, LayoutStyle>((theme: Theme) =>
  createStyles({
    root: {},
    fullHeight: {
      [theme.breakpoints.up('sm')]: {
        minHeight: '100%',
      },
    },
    isAbsoluteHidden: {
      opacity: 0.5,
    },
    count: {
      padding: theme.spacing(1, 0, 0, 2),
      marginTop: 0,
      fontWeight: 400,
      color: theme.extraColors.greyText,
    },
    bold: {
      fontWeight: 'bold',
    },
    footerRow: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(1, 2),
      gap: theme.spacing(2),
      margin: '0 !important',
      borderTop: '1px solid black',
      fontSize: '13px',
    },
    basketBtn: {
      padding: 0,
      minWidth: 0,
      width: theme.sizes.headerHeight,
      height: theme.sizes.headerHeight,
      color: (LayoutStyle) =>
        !LayoutStyle.isTransparent
          ? LayoutStyle.color
            ? LayoutStyle.color
            : theme.palette.common.black
          : theme.palette.common.white,
      '&:hover, &:focus': {
        color: (LayoutStyle) =>
          !LayoutStyle.isTransparent &&
          (LayoutStyle.backgroundColor &&
          LayoutStyle.color &&
          LayoutStyle.hover.color
            ? LayoutStyle.hover.color
            : theme.palette.primary.main),
        backgroundColor: 'transparent',
        cursor: 'pointer',
      },
      '&.Mui-disabled': {
        color: 'currentColor',
      },
    },
    basketBtnReadOnly: {
      cursor: 'default',
      pointerEvents: 'none',
      '&:hover, &:focus': {
        cursor: 'default',
        pointerEvents: 'none',
      },
    },
    basketBtnText: {
      marginLeft: theme.spacing(1),
    },
    basketBtnIcon: {
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
    },
    basketBtnIconSvg: {
      lineHeight: 1,
      '& .injected-svg': {
        display: 'inline-block',
        width: '1em',
        height: '1em',
        fontSize: '1.5rem',
        fill: 'currentColor',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
      },
    },
    badge: {
      position: 'absolute',
      bottom: theme.spacing(1),
      right: theme.spacing(1),
      padding: theme.spacing(0, 0.5),
      height: 20,
      lineHeight: '20px',
      minWidth: 20,
      borderRadius: theme.shape.borderRadius,
      fontSize: 13,
      fontWeight: 700,
      textAlign: 'center',
      border: `1px solid ${theme.palette.common.white}`,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    basket: {
      padding: theme.spacing(1, 2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.up('md')]: {
        minHeight: '100%',
      },
    },
    basketBG: {
      backgroundColor: theme.extraColors.greyLight,
    },
    basketContent: {
      padding: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(2, 6),
      },
    },
    basketFooter: {
      zIndex: 10,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      padding: theme.spacing(1),
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(2),
      },
    },
    basketProducts: {
      display: 'flex',
      flexDirection: 'column',
    },
    basketProductsCart: {},
    basketProductsFunnel: {
      [theme.breakpoints.up('md')]: {
        maxHeight: '55vh',
        overflow: 'auto',
      },
    },
    basketProductsList: {},
    reset: {
      color: theme.extraColors.greyText,
      margin: theme.spacing(0, 1, 0, 0),
      '&>img': {
        verticalAlign: 'middle',
      },
    },
    resetSmall: {
      padding: 0,
      fontSize: theme.typography.caption.fontSize,
      color: theme.palette.error.main,
      '&:hover, &:focus': {
        background: 'none',
      },
    },
    backdrop: {
      backgroundColor: 'rgba(40, 42, 37, .15)',
    },
    row: {
      display: 'flex',
      padding: theme.spacing(1, 0.5),
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    rowShipping: {
      display: 'inline-flex',
      justifyContent: 'center',
      width: '100%',
      '&>div': {
        marginLeft: 0,
      },
    },
    next: {},
    alignCenter: {
      textAlign: 'center',
      width: '100%',
    },
    close: {
      cursor: 'pointer',
      [theme.breakpoints.up('lg')]: {},
    },
    smallText: {
      display: 'block',
      color: theme.extraColors.greyText,
    },
    totalDetails: {
      marginLeft: theme.spacing(1),
      fontWeight: 700,
    },
    rowFooterSub: {
      marginTop: 0,
    },
    shipping: {
      fontSize: '80%',
      fontWeight: 400,
      color: `${theme.extraColors.greyText}`,
    },
    basketTotalMixed: {
      borderTop: 0,
      borderBottom: `1px solid ${theme.extraColors.grey}`,
      marginBottom: theme.spacing(1),
    },
    basketActions: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    basketActionsDelete: {
      justifyContent: 'center',
    },
    basketLinkToProductCard: {
      textAlign: 'center',
    },
    basketCheckboxValidation: {
      display: 'flex',
      alignItems: 'flex-start',
    },
  })
)
