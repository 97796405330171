import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { createStyles, makeStyles, Theme } from '@material-ui/core'

interface FullScreenDialog {
  open: boolean
  setOpen: (open: boolean) => void
  children?: React.ReactNode
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogHeader: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: 20,
      paddingTop: 10,
    },
    dialogContent: {
      display: 'flex',
      paddingTop: 100,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 10,
      },
      justifyContent: 'center',
      height: '100%',
    },
  })
)

const FullScreenDialog = ({ open, setOpen, children }: FullScreenDialog) => {
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <div className={classes.dialogHeader}>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.dialogContent}>{children}</div>
    </Dialog>
  )
}

export default FullScreenDialog
