import React from 'react'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'

interface LinkProps extends NextLinkProps {
  children: JSX.Element
}

const Link = (props: LinkProps) => {
  const { href, ...otherProps } = props
  const productionDomain = process.env.PRODUCTION_DOMAIN

  let origin
  if (typeof window !== 'undefined') {
    origin = window.location.origin
  }

  let relativeHref
  if (origin && typeof href === 'string' && href.includes(productionDomain)) {
    relativeHref = href.replace(productionDomain, origin)
  }

  return <NextLink href={relativeHref || href} {...otherProps} />
}

export default Link
